:root {
  /* OLD COLOR VARIABLES ---------------------------------------------------------- */
    /* nav colors */
    /* --primary-header-blue: #52b1bd;
    --secondary-header-blue: #2a9eb2;
    --nav-hover: #25707b; */


    /* left side bar colors */
    /* --left-sidebar-bg: #9eedf0;
    --gray-bg: #6b6b6b35;
    --gray-bg-border: #7474745d; */

    /* --------------------------NEW COLOR VARIABLES ------------------------------- */
    /* Primary Color */
  --primary-color: hsl(164, 14%, 40%);
  --primary-white: hsl(0, 0%, 90%);
  --secondary-white: hsl(0, 0%, 70%);
  /* Text Colors */
  --text-primary: hsl(0, 0%, 90%);
  --text-secondary: hsl(0, 0%, 70%);
  --text-dark: hsl(0, 0%, 8%);

  /* Container Card*/
  --card-color : rgba(143, 154, 141, 0.258);
  /* Shadow Colors */
  --shadow-color: hsla(0, 5%, 75%, 0.652);
  --text-shadow-color: hsla(0, 0%, 0%, 0.5);
  /* Border Colors */
  --border-primary: hsla(0, 0%, 55%, 0.573);
  --border-secondary: hsl(210, 12%, 30%);

  /* Slider Colors */
  --slider-track: hsl(164, 14%, 40%);
  --slider-thumb-border: hsl(164, 14%, 40%);

  /* Switch Colors */
  --switch-shadow: hsla(0, 5%, 75%, 0.652);
  --switch-off-background: hsla(165, 19%, 63%, 0.38);
  --switch-on-background: hsl(165, 19%, 63%);
  --switch-thumb-on: hsl(0, 0%, 100%);
  --switch-thumb-off: hsla(134, 16%, 84%, 0.845);

  /* Button Colors */
  --button-primary-background: linear-gradient(45deg, #91bfa2, #bde8df, #91bfa2);
  --button-primary-text: hsl(0, 0%, 90%);
  --button-secondary-background: hsl(210, 12%, 30%);
  --button-secondary-text: hsl(0, 0%, 90%);

  /* Navbar Colors */
  --navbar-background: hsla(0, 0%, 64%, 0.068);
  --navbar-border: hsla(130, 36%, 83%, 0.547);
  --navbar-link-regular: hsl(0, 0%, 90%);
  --navbar-link-hover: hsl(164, 14%, 60%);
  --navbar-link-active: hsl(164, 14%, 50%);
  --navbar-on-click-background: hsl(164, 14%, 30%);
  --navbar-on-click-text: hsl(0, 0%, 90%);
  --navbar-on-hover-background: hsl(210, 12%, 20%);
  --navbar-on-hover-text: hsl(164, 14%, 60%);

  --main-dark-gradient: linear-gradient(
    45deg,
    hsl(210deg 12% 10%) 0%,
    hsl(200deg 9% 7%) 20%,
    hsl(159deg 15% 23%) 40%,
    hsl(164deg 14% 27%) 60%,
    hsl(214deg 9% 15%) 80%,
    hsl(210deg 12% 10%) 100%
  );
  }

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    display: flex;
    flex-direction: column;
    /* overflow-y: hidden; */
    height: 100vh;
    color : var(--text-primary);
    background-image: var(--main-dark-gradient);
  }