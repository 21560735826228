/* Dropzone css */
  .dicomDropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed var(--primary-white);
    padding: 50px;
    text-align: center;
    border-radius: 5px;
    margin: 0 auto;
    width: 60%;
    height: 40%;
  }
    
  .dropzone-active {
    background-color: #256f7b4d; /* Light background change */
    border-color: #007bff;  /* Highlight border color  */
  }
  
  .dropzone-invalid {
    background-color: #256f7b72; /* Light red background */
    border-color: #dc3545; /* Red border */ 
  }
  
  .drag-and-drop-text {
    margin-bottom: 10px; 
  }
  
  .upload-button {
    background-color: #3B4B4DE0; 
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;  
    transition: background-color 0.3s ease; /* Add a transition */
  }
  
  .upload-button:hover {
    background-color: #3B4B4DAB; /* Slightly darker on hover */
  }
  
  /* DICOM image viewer css */
  .dicomImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* background-color: #6fc9cc; */
  }
  .image-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  .image-viewer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    position: relative;
    height: calc(100% - 50px);
  }
  
  .viewport-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
  }
