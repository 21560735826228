.anatomy-group,
.pathology-scores-group,
.pathology-list-group {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin: 1rem;
  padding: 0 0.5rem;
  border: 0.5px solid var(--border-primary);
  border-radius: 5px;
  background-color: var(--card-color);
  box-shadow: 0 0 6px 0 var(--shadow-color);
  }

   
.highlight-anatomy-btn-div,
.pathology-btn-div,
.generate-report-btn,
.pathology-prediction-btn-div {
  display: flex;
  justify-content: center;
  margin: 1rem;
  padding: 0 0.5rem;
}

/* .generate-report-btn-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
} */