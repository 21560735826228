.report-gen-area {
    padding: 1rem;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .details-section,
  .gen-report-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin: 1rem;
    padding: 0.5rem;
    border: 0.5px solid var(--border-primary);
    border-radius: 5px;
    background-color: var(--card-color);
    box-shadow: 0 0 6px 0 var(--shadow-color);
    }

    .divider-line {
      width: 50%;
    height: 1px;
    background-color: var(--secondary-white);
    /* margin-top: -0.5rem; */
    margin-left: 0.2rem;
    margin-bottom: 1rem;
    }
    
    
    .details-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    
    .details-field {
      display: flex;
      align-items: center;
      width: 48%;
    }
    
    .details-field label {
      margin-right: 10px;
      font-weight: bold;
    }
    
    .patient-details-input {
      flex: 1;
    }