.immersive-viewer-page {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 7rem); /* Take into account navbar height */
    padding : 1rem;
    background-color: black;
}

.dicom-viewports-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .dicom-viewport {
    flex: 1;
    margin: 0 0.5rem;
  }
  
  .ct-image-container {
    width: 100%;
    height: 400px;
    text-align: center;
  }