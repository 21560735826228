.reportgen-container {
    display: flex;
    height: calc(100vh - 7rem);
  }
  
  .report-gen-image-viewer-container {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
