/* Toolbar.css */
.toolbar {
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: #000000;
  width: 100%;
  box-sizing: border-box;
}

.toolbar-container {
  display: flex;
  justify-content: center;
  background-color: #333333;
  padding: 5px;
  border-radius: 4px;
}

.toolbar-button {
  margin: 0 5px;
  color: #e6e6e6;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s;
}

.toolbar-button:hover {
  background-color: #555555;
}

.toolbar-button:hover .MuiSvgIcon-root {
  color: #e6e6e6;
}

.toolbar-button.active {
  background-color: #e6e6e6;
  color: #333333;
  border-radius: 5px;
}

.toolbar-button.active:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.toolbar-button.active .MuiSvgIcon-root {
  color: #333333;
  border-radius: 5px;
}

.toolbar-button .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  color: #e6e6e6;
  transition: color 0.2s;
}

.toolbar-button .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  color: #e6e6e6;
}