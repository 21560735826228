.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
  }
  /* Logo and app name container */
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    box-shadow: 0 5px 9px rgba(56, 56, 56, 0.1);
  }

  .logo--img {
    width: 100%;
    max-width: 5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .logo--name {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .logo--link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  /* Navigation links container */
  nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    background-color: var(--navbar-background);
    font-size: 1.1rem;
    box-shadow: 0 5px 9px rgba(56, 56, 56, 0.1);
  }

  .nav-links {
    width: 100%;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-around;
    border-bottom: 1px solid var(--navbar-border);
    border-top: 1px solid var(--navbar-border);
  }

  .nav-links li {
    padding: 0 1.5rem; 
  }
  
  .nav-links li a { 
    display: flex; 
    align-items: center;
    height: 3.5rem;   
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease-in-out; 
    padding: auto; 
    outline: none;
  }
  
  .nav-links a:hover,
  .nav-links a:active { 
    background-color: var(--navbar-link-hover);
    color: var(--text-dark);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0 2rem;
  }

  .nav-links a.active {
    background-color: var(--navbar-link-hover);
    color: var(--text-dark);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0 2rem;
  }