/* AdvancedPreScreening main page CSS */
.page-container {
  display: flex; 
  height: calc(100vh - 7rem); /* Take into account navbar height */
  color : var(--text-primary);
}

.left-sidebar,
.image-viewer,
.right-sidebar {
  box-sizing: border-box;
  overflow-y: auto;
  transition: flex 0.3s ease-in-out;
}

::-webkit-scrollbar {
  width: 10px; /* Adjust width */
  background-color: hsla(0, 0%, 20%, 0.5); /* Dark gray with transparency */
}

::-webkit-scrollbar-thumb {
  background-color: hsl(165, 19%, 63%);; /* Primary green color */
  border-radius: 6px; /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(164, 14%, 30%); /* Slightly darker green on hover */
}

::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 10%, 0.5); /* Dark black with transparency */
}

.left-sidebar {
  flex: 2 0 0;
  background-color: #ffffff10;
}

.left-sidebar.closed {
  flex: 0 0 0;
}

.image-viewer {
  flex: 6;
  /* background-color: #7ad3d6be; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid var(--navbar-border); */
}

.image-viewer.expand {
  flex: 8;
}

.right-sidebar {
  flex: 2;
  /* background-color: var(--left-sidebar-bg); */
  background-color: #ffffff10;
}

.right-sidebar.closed {
  flex: 0 0 0;
}

  /*----------------------------- Left side bar CSS -----------------------------*/
 /* Anatomy Selector style */
  .anatomy-selector-container,
  .classification-confidence-container,
  .prelim-report-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin: 1rem;
    padding: 0 0.5rem;
    border: 0.5px solid var(--border-primary);
    border-radius: 5px;
    background-color: var(--card-color);
    box-shadow: 0 0 6px 0 var(--shadow-color);
  }

  .anatomy-selector-divider {
    width: 50%;
    height: 1px;
    background-color: var(--secondary-white);
    /* margin-top: -0.5rem; */
    margin-left: 0.2rem;
    margin-bottom: 1rem;
  }

  .anatomy-selector--col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    
  }
  
  .anatomy-selector--col1 {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .anatomy-selector--col2 {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
  }
  

  .slider {
    display: block;
    padding-bottom: 15px;
    padding-top: 10px;
    width: 95%;
    margin: 0 auto;
  }


  .prelim-report-textarea {
    width: 95%;
    height: 6rem;
    margin: 0 auto;
    }
  
    .start-prescreening-btn {
      display: flex;
      justify-content: center;
    }

    @keyframes skeleton-loading {
      0% {
        background-color: #E0E0E0;
      }
      50% {
        background-color: #F5F5F5;
      }
      100% {
        background-color: #E0E0E0;
      }
    }

    /* ------------------------------ Right side bar CSS ------------------------------ */
    .contrast-slider, 
    .brightness-slider, 
    .threshold-slider, 
    .right-sidebar-switches {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      margin: 1rem;
      padding: 0 0.5rem;
      border: 0.5px solid var(--border-primary);
      border-radius: 5px;
      background-color: var(--card-color);
      box-shadow: 0 0 6px 0 var(--shadow-color);
    }
 